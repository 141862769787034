import React, { Component } from 'react';
import { Container } from "semantic-ui-react";
import ReactPixel from 'react-facebook-pixel';
import _ from 'underscore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import PaymentModal from './PaymentModal';
// import OutOfCapacityModal from './OutOfCapacityModal';

import './css/login.css';
import paymentLogo from '../landingpage/images/payments/payment.png'; 
import sslImg from '../landingpage/images/certificates/ssl.png';
import atShopImg from '../landingpage/images/certificates/at_shop.png';
import datenschutzImg from '../landingpage/images/certificates/datenschutz.png';

import i18n from "../utils/i18n";

const stripe = window.Stripe && window.Stripe("pk_live_LBs9pdWo14TDAeWkrkegsXsJ00YeCtbs5n");

const PAYMENT_SMALL_SDK = "sku_IGK1Mda6gZ5xNF";
const PAYMENT_MEDIUM_SDK = "sku_IGK12mU6Z7HuRB";
const PAYMENT_LARGE_SDK = "sku_IGK0z5lqur0Qjw";

const DREIECK_STRIPE = "sku_KLdtk5CnjAvhTq";
const SCHLUESSELANHAENGER_STRIPE = "sku_KLdthL5srUGpLq";
const FLIGHTCOMPUTER_STRIPE = "sku_KLdqKbZCLgwo2J";
const HEADSET_STRIPE = "sku_Knayal9G2ThYuL";

class RequestLoginData extends Component {
  constructor(props) {
    super(props);

    let product = null;
    // let lang = null;
    const location = this.props.location;
    if (location && location.location && location.location.search) {
      product = this.extractUrlValue("product", location.location.search);
      // lang = this.extractUrlValue("lang", location.location.search);
    }

    this.state = { 
      showPaymentChooseMethodeDialog: product ? true : false,
      product,
    };

    this.inverval = null;

    this.closeDialog = this.closeDialog.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.onPaymentSelection = this.onPaymentSelection.bind(this);
    this.stripeCheckout = this.stripeCheckout.bind(this);
    this.onPayPalCancel = this.onPayPalCancel.bind(this);
    this.onPayPalError = this.onPayPalError.bind(this);
    this.onPayPalSuccess = this.onPayPalSuccess.bind(this);
    this.updateCouponCode = this.updateCouponCode.bind(this);
    this.sendCouponCode = this.sendCouponCode.bind(this);
    this.showCouponCodeInput = this.showCouponCodeInput.bind(this);
    this.checkPaymentState = this.checkPaymentState.bind(this);
    this.sofortCheckout = this.sofortCheckout.bind(this);
    this.fetchClientSecret = this.fetchClientSecret.bind(this);
    this.checkSOFORTPayment = this.checkSOFORTPayment.bind(this);
  }

  componentDidMount() {
    const advancedMatching = {};
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('711428279376703', advancedMatching, options);
    this.checkPaymentState(window.location.hash);
  }

  extractUrlValue(key, string) {
    const match = string.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }

  checkPaymentState(hash) {
    const search = window.location.search;
    if (search.indexOf("payment_intent_client_secret") > 0) {
      this.checkSOFORTPayment();
      return null;
    }

    if (!hash) return null;
    if (hash.indexOf("success") > 0) {
      this.notify(true);
    } else if (hash.indexOf("cancel") > 0) {
      this.notify(false);
    } 
  }

  notify(success) {
    if (success) {
      ReactPixel.track("Purchase",{ value: 59.00, currency: 'EUR' });
      const callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = window.location.origin + '/successful';;
        }
      };
      window.gtag('event', 'conversion', {
        'send_to': 'AW-1036337835/Y0kwCMPjgIsCEKuFle4D',
        'value': 59.0,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': callback
      });
    } else {
      clearInterval(this.inverval);
      toast.error(i18n("payment_not_successfull"));
    }
  }

  openDialog() {
    this.setState({
      showPaymentChooseMethodeDialog: true,
    });
  }

  closeDialog() {
    this.setState({
      showPaymentChooseMethodeDialog: false,
    });
  }

  onPaymentSelection(option, time, products, options) {
    this.closeDialog();
    if (option === "creditcard") {
      // Facebook Pixel
      ReactPixel.trackCustom("Weiter zu Stripe!", {});
      this.stripeCheckout(time, products);
    } if (option === "sofort") {
      // Facebook Pixel
      ReactPixel.trackCustom("Weiter zu Stripe!", {});
      this.sofortCheckout(time, products, options);
    }
  }

  getPackageSDKs(includeHeadset, includeFlightcomputer) {
    const items = [
      {sku: DREIECK_STRIPE, quantity: 1},
      {sku: SCHLUESSELANHAENGER_STRIPE, quantity: 1},
    ];
    if (includeFlightcomputer) {
      items.push({sku: FLIGHTCOMPUTER_STRIPE, quantity: 1});
    }
    if (includeHeadset) {
      items.push({sku: HEADSET_STRIPE, quantity: 1});
    }
    return items;
  }

  stripeCheckout(time, products) {
    const sdk = this.getSDK(time);
    let items = [{sku: sdk, quantity: 1}];
    const includeHeadset = products["headset"];
    const includeFlightcomputer = products["flightcomputer"];
    if (includeHeadset || includeFlightcomputer) {
      var packageItems = this.getPackageSDKs(includeHeadset, includeFlightcomputer);
      _.each(packageItems, function (item) {
        items.push(item);
      });
    }
    stripe.redirectToCheckout({
      billingAddressCollection: 'required',
      items: items, // live-mode
      successUrl: window.location.origin + '/login#success',
      cancelUrl: window.location.origin + '/login#canceled',
    })
    .then(function (result) {
      if (result.error) {
        ReactPixel.trackCustom("Kreditkarte Error", {});
        const error = result.error.message;
        console.log(error);
      }
    });
  }

  async fetchClientSecret(time, products, user) {
    let product = "basic";
    if (time === "6") product = "basic";
    if (time === "12") product = "premium";
    if (time === "24") product = "ultimate";

    const data = {
      product: product,
      user: user,
      products: products,
    };

    const response = await fetch('https://api.ppltrainer.at/stripe/payment-intent.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  }

  sofortCheckout(time, products, options) {
    this.fetchClientSecret(time, products, options.user).then(res => {
      stripe.confirmSofortPayment(
        res.clientSecret,
        {
          payment_method: {
            sofort: {
              country: options.country
            },
          },
          return_url: window.location.origin + '/login',
        }
      );
    });
  }

  checkSOFORTPayment() {
    var url = new URL(window.location);
    const that = this;
    var clientSecret = url.searchParams.get('payment_intent_client_secret');
    stripe.retrievePaymentIntent(clientSecret).then(function(response) {
      if (response.error) {
        // Handle error here
        that.notify(false);
      } else if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
        // Handle successful payment here
        that.notify(true);
      }
    });

    if (this.inverval) return;
    this.inverval = window.setInterval(function() {
      that.checkSOFORTPayment();
    }, 1500);
  }

  getSDK(time) {    
    let sdk = PAYMENT_MEDIUM_SDK; // default 12 month
    if (time === "6") sdk = PAYMENT_SMALL_SDK // 6 month
    if (time === "24") sdk = PAYMENT_LARGE_SDK; // 24 month
    return sdk;
  }

  onPayPalError() {
    ReactPixel.trackCustom("PayPal Error", {});
    this.closeDialog();
    this.notify(false);
  }

  onPayPalSuccess() {
    this.closeDialog();
    this.notify(true);
  }

  onPayPalCancel() {
    ReactPixel.trackCustom("PayPal abgebrochen", {});
    this.closeDialog();
    this.notify(false);
  }

  showCouponCodeInput() {
    this.setState({
      showCouponCodeContainer: true,
    })
  }

  sendCouponCode() {
    if (!this.state.couponCode) return;
    const emailURL = `
      mailto:office@ppltrainer.at
      ?subject=Access Code: "${this.state.couponCode}"
    `;
    window.open(emailURL);
  }

  updateCouponCode(element) {
    const couponCode = element.target.value;
    this.setState({
      couponCode,
    })
  }

  render() {
    return (
      <Container textAlign="center" className="request-login">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
          />
        <div className="request-login-inner">

          <div className="no_account_available_link" onClick={this.openDialog}>{i18n("no_account_available")}</div>

          <div>{ 
            this.state.showPaymentChooseMethodeDialog ? 
              <PaymentModal defaultProduct={this.state.product} onSelect={this.onPaymentSelection} onAbort={this.closeDialog} onPayPalError={this.onPayPalError} onPayPalSuccess={this.onPayPalSuccess} onPayPalCancel={this.onPayPalCancel} />
              // <OutOfCapacityModal onAbort={this.closeDialog} />
            :
              <button onClick={this.openDialog} className="buy-btn">{i18n("buy_ppltrainer_here")}</button>
          }</div>

          <img className="payment-methods-login" title={i18n("zahlungsmoeglichkeiten")} alt={i18n("zahlungsmoeglichkeiten")} src={paymentLogo} />

          <div>
            <img className="certificate-item" title={i18n("zahlungsmoeglichkeiten")} alt={i18n("zahlungsmoeglichkeiten")} src={atShopImg} />
            <img className="certificate-item w-65" title={i18n("zahlungsmoeglichkeiten")} alt={i18n("zahlungsmoeglichkeiten")} src={datenschutzImg} />
            <img className="certificate-item w-85" title={i18n("zahlungsmoeglichkeiten")} alt={i18n("zahlungsmoeglichkeiten")} src={sslImg} />
          </div>

          <div className="coupon-container">
            <div className="action" onClick={this.showCouponCodeInput}>{i18n("do_you_have_a_coupon")}</div>
            {
              this.state.showCouponCodeContainer ?
                <div className="coupon-container-input">
                  <input type="text" onChange={this.updateCouponCode} />
                  <button className="button button-primary" onClick={this.sendCouponCode}>{i18n("activate_coupon")}</button>
                </div>
              : null
            }
          </div>

        </div>
      </Container>
    );
  }
}

export default RequestLoginData;
