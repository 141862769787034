import React, { Component } from 'react';
import _ from 'underscore';
import moment from 'moment';
import { Icon, Modal, Dropdown, Checkbox } from 'semantic-ui-react';
import $ from 'jquery';
import i18n from '../../../utils/i18n';

class ExamFormular extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      type: null,
      selectedStudents: {},
      selectedCategories: [],
      questionCount: 16,
      examPercentage: 75,
      noEndDate: false,
      enddate: moment().add(2, 'hours').format("YYYY-MM-DDTHH:mm"), // in 2 hours
    };

    this.defaultCategories = ["ALW","HPL","MET","COM","PFA","PFH","PFB","OPR","FPP","AGK","NAV","NVFR"];
    this.selectableGroups = _.map(this.props.groups, function(group) { 
      return {
        key: group,
        text: group,
        value: group,
      }
    });
    this.update = this.update.bind(this);
    this.start = this.start.bind(this);
    this.selectType = this.selectType.bind(this);
    this.noGroupsAvailable = this.noGroupsAvailable.bind(this);
    this.getListOfAvailableCategories = this.getListOfAvailableCategories.bind(this);
    this.getListOfStudents = this.getListOfStudents.bind(this);
  }

  update(options, data) {
    options = options || {};
    let name = options.target.name || (data && data.name);
    let value = options.target.value || (data && data.value);
    let { selectedCategories, selectedStudents } = this.state;
    let obj = {};

    // when selecting students
    if (data && data.selector && data.selector === 'students') {
      name = 'selectedStudents';
      const exists = selectedStudents[data.id];
      if (!exists) {
        selectedStudents[data.id] = data.label;
      } else {
        // remove it
        delete selectedStudents[data.id];
      }
      value = selectedStudents;
    }

    // selecting questionCount
    if (name === "questionCount" || name === "examPercentage") {
      value = parseInt(value);
    }

    if (name === "noEndDate") {
      value = data.checked;
    }
    
    // when selecting categories
    if (!name && !value) {
      name = data && data.id;
      if (data.checked) {
        const exists = selectedCategories.includes(name);
        // IF exists remove
        if (!exists) {
          selectedCategories.push(name);
        } else {
          const index = selectedCategories.indexOf(name);
          if (index > -1) selectedCategories.splice(index, 1);
        }
      } else {
        selectedCategories = selectedCategories.filter(item => item !== name);
      }
      obj = { selectedCategories };
    } else {
      obj[name] = value;
    }
    
    this.setState(obj, () => {
      const {type, group, selectedCategories, enddate, selectedStudents, questionCount, examPercentage, noEndDate} = this.state;
      if (type === 'students') {
        this.props.updateContent({
          data: {"exam": { selectedStudents, selectedCategories, enddate, questionCount, examPercentage, noEndDate }}, 
          id: 'exam'
        });
      } else {
        this.props.updateContent({
          data: {"exam": { group, selectedCategories, enddate, questionCount, examPercentage, noEndDate }}, 
          id: 'exam'
        });
      }
    });
  }

  start(action) {
    this.props.onCallInnerManagerAction({ action });
  }

  noGroupsAvailable() {
    return !this.props.groups || _.isEmpty(this.props.groups);
  }

  getTranslation(key) {
    return i18n(key) || key;
  }

  getListOfAvailableCategories() {
    const that = this;
    const categories = this.defaultCategories.concat(this.props.categories);
    return _.map(categories, function(category) {
      return <Checkbox key={category} onChange={(options, data) => that.update(options, data)} id={category} className="mm-form-hue-item" label={that.getTranslation(category)} />;
    });
  }

  getListOfStudents() {
    const that = this;
    const students = _.filter(this.props.students, function (student) { return student.verified; });
    return _.map(students, function(student) {
      return <Checkbox key={student.email} onChange={(options, data) => that.update(options, data)} selector='students' id={student.email} label={student.name} className="mm-form-hue-item flex-50" />;
    });
  }

  selectType(type, element) {
    $('.choose-exam').removeClass('is-selected');
    $('.' + element).addClass('is-selected');
    this.setState({ type })
  }

  render() {
    if (this.noGroupsAvailable()) {
      // TODO nicer Alert
      alert(i18n('first_add_a_group'));
      this.props.onClose();
      return "";
    }
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_a_new_exam")}</Modal.Header>
      <Modal.Description>
        <div>
          <div className='mm-description-item'>{i18n("all_exams_contains_secret_flightschool_questions")}</div>
        </div>
      </Modal.Description>
      <div className='mm-form-container create-exam'>
        <div className='exams-box mt-10'>
          <div className='headline'>{i18n("online")} <span className='headline-description'>{i18n("start_exam_online_or_set_it_available_for_students")}</span></div>
          <div className='exams-box-item'>
            <div className='choose-exam exam1 hidden' onClick={() => this.start("startExam")}>
              <img src="/img/ato/exam.png" alt={i18n("start_exam")} />
              <span className="box-headline">{i18n("start_exam")}</span>
            </div>
            <div className='choose-exam exam2' onClick={() => this.selectType("class", "exam2")}>
              <img src="/img/ato/students.png" alt={i18n("create_class_homework")} />
              <span className="box-headline">{i18n("create_class_homework")}</span>
            </div>
            <div className='choose-exam exam3' onClick={() => this.selectType("students", "exam3")}>
              <img src="/img/ato/student.png" alt={i18n("create_homework_for_certain_students")} />
              <span className="box-headline">{i18n("create_homework_for_certain_students")}</span>
            </div>
          </div>
        </div>

        { // Show 'print and template' exam box if no type (class or student) is selected
          this.state.type === null &&
          <div>
            <div className='exams-box'>
              <div className='headline'>{i18n("print")} <span className='headline-description'>{i18n("print_a_new_exam")} </span></div>
              <div className='exams-box-item'>
                <div className='choose-exam exam1' onClick={() => this.start("printExam")}>
                  <img src="/img/ato/print.png" alt={i18n("print_exam")} />
                  <span className="box-headline">{i18n("print_exam")}</span>
                </div>
              </div>
            </div>
            <div className='exams-box in-development hidden'>
              <div className='headline'>{i18n("template")} <span className='headline-description'>{i18n("use_a_template")}</span></div>
              <div className='exams-box-item'>
                <div className='choose-exam exam1 in-development'>
                  <img src="/img/ato/template.png" alt={i18n("create_template")} />
                  <span className="box-headline">{i18n("create_template")}</span>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="templates-container">
          
          { // Show next when group is choosen
            this.state.type === 'class' &&
            <><div className='headline'>{i18n("choose_class")} <span className='headline-description'>{i18n("choose_class_exam_hint")}</span></div><div className='mm-form-item form-item-padding-bottom'>
                <Icon name="list" title={i18n("choose_class_exam_hint")} />
                <Dropdown
                  placeholder={i18n("class")}
                  fluid
                  selection
                  options={this.selectableGroups}
                  name="group"
                  onChange={(options, data) => this.update(options, data)} />
              </div></>
          }

          { // Show next when group is choosen
            this.state.type === 'students' &&
            <><div className='headline'>{i18n("choose_student")} <span className='headline-description'>{i18n("choose_student_exam_hint")}</span></div><div className='mm-form-item flex-wrap'>
                { this.getListOfStudents() }
              </div></>
          }

          { // Show next when group is choosen
            this.state.group || !_.isEmpty(this.state.selectedStudents) ?
              <div>
                <div className='headline'>{i18n("choose_categories")} <span className='headline-description'>{i18n("which_categories_should_be_done_as_exam")}</span></div>
                <div className='mm-form-item-list'>
                  { this.getListOfAvailableCategories() }
                </div>
                <div>
                  <div className='headline'>{i18n("full_count_for_homework")} <span className='headline-description'>{i18n("full_count_for_exam_description")}</span></div>
                  <div className='mm-form-item-list'>
                    <span>{i18n("number")}: <b>{this.state.questionCount}</b></span>
                    <input onChange={this.update} min="1" max="50" name="questionCount" step="1" type="range" value={this.state.questionCount}></input>
        
                  </div>
                </div>
                <div>
                  <div className='headline'>{i18n("minimum_exam_percentage")} <span className='headline-description'>{i18n("full_minimum_exam_percentage")}</span></div>
                  <div className='mm-form-item-list'>
                    <span>{i18n("percentage")}: <b>{this.state.examPercentage}%</b></span>
                    <input onChange={this.update} min="1" max="100" name="examPercentage" step="1" type="range" value={this.state.examPercentage}></input>
        
                  </div>
                </div>
                <div>
                  <div className='headline'>{i18n("choose_enddate")} <span className='headline-description'>{i18n("by_when_must_the_exam_be_done")}</span></div>
                  <div className='mm-form-item-list item-enddate-special form-item-padding-bottom'>
                    <input className='input-date input-date-extra-large' disabled={this.state.noEndDate} type="datetime-local" name="enddate" onChange={this.update} value={this.state.enddate} min={moment().format("YYYY-MM-DDTHH:mm")} max={moment().add(2, 'weeks').format("YYYY-MM-DDTHH:mm")} />
                    <Checkbox key="noEndDate" onChange={(options, data) => this.update(options, data)} name="noEndDate" defaultChecked={this.state.noEndDate} className="mm-form-hue-item" label={i18n("no_enddate")} />
                  </div>
                </div>
              </div>
            : null
          }

        </div>
      </div>  
    </Modal.Content>
  }
}

export default ExamFormular;
