import React, { Component } from 'react';
import _ from 'underscore';
import { Checkbox, Icon } from 'semantic-ui-react'

import QuestionContent from './QuestionContent';

import i18n from "../utils/i18n";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class SimulationOverview extends Component {
  constructor(props) {
    super(props);
    this.getLocalStorage = this.getLocalStorage.bind(this);
    this.prepareCategoryList = this.prepareCategoryList.bind(this);
    this.getLocalStorageData = this.getLocalStorageData.bind(this);
    this.getFlightschoolQuestionCountPerCategory = this.getFlightschoolQuestionCountPerCategory.bind(this);
    this.getRadioQuestionCountPerCategory = this.getRadioQuestionCountPerCategory.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.createExam = this.createExam.bind(this);
    this.completed = false;
    this.state = {
      printExamTopics: ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV"],
    }
  }

  getLocalStorageData() {
    let data = JSON.parse(localStorage.getItem('ppltrainer'));
    if (!data) {
      data = {
        motor: true,
        heli: false,
        segel: false,
        ballon: false,
        country: 'AT',
        language: "de",
      }
    }
    return data;
  }

  getTranslationFromFlightSchoolCategories(key) {
    var { flightschool_categories } = this.props;
    var item = _.find(flightschool_categories, function (category) {
      return "FS_" + category.id === key; 
    });
    return (item && item.category) || false;
  }

  getTranslationForRadioCategories(key) {
    switch(key) {
      case "DE_bzf1":
        return "BZF I";
      case "DE_azf":
        return "AZF";
      case "DE_bzf2":
        return "BZF II";
      case "AT_bfz":
        return "BFZ";
      case "AT_efz":
        return "EFZ";
      case "AT_afz":
        return "AFZ";
      default:
        return false;
      }
  }

  getTranslation(value) {
    var preTranslation = i18n(value);
    if (preTranslation) return preTranslation;
    var key = value.split("_")[1] || value;
    var translatedValue = i18n(key);
    return  translatedValue || this.getTranslationFromFlightSchoolCategories(key) || this.getTranslationForRadioCategories(key) || key;
  }

  getSessionExam() {
    return JSON.parse(localStorage.getItem("ppltrainer_exam_session"));
  }

  unsetSessionExam() {
    localStorage.removeItem("ppltrainer_exam_session");
  }

  createExam() {
    var userinfo = JSON.parse(localStorage.getItem('okta-token-storage'));

    const company = this.props.atoFullName || userinfo.idToken.claims.ato_name || "ppltrainer";

    const content = [
      {
        text: i18n("ppl_preliminary_examination"),
        style: 'header'
      },
      {
        text: i18n("flightschool") + ': ' + company,
        style: 'subheader'
      }
    ];

    this.unsetSessionExam();

    let printCategories = this.state.printExamTopics;
    // removed not needed categories
    this.props.onPrintNewExam(content, printCategories);
  }

  onSelectCategoryForPrintExam(key) {
    let arr = this.state.printExamTopics;
    if (arr.includes(key)) {
      arr = arr.filter(function(item) {
        return item !== key;
      });
    } else {
      arr.push(key);
    }
    this.setState({ printExamTopics: arr });
  }

  onSelectCategory(key, isDone) {
    if (isDone) return;
    this.props.onSelectCategory(key);
  }

  getFlightschoolQuestionCountPerCategory() {
    const questions = this.props.flightschool_questions;
    const data = {};
    _.each(questions, function (question, key) {
      data[key] = question && question.data.length; 
    });
    return data;
  }

  getRadioQuestionCountPerCategory(radio_categories) {
    const questions = this.props.radio_questions;
    const data = {};
    _.each(questions, function (question, key) {
      data[key] = question && question.data.length; 
    });
    return data;
  }

  isDefaultTopicForPrintExam(key) {
    const list = ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV"];
    return list.includes(key);
  }

  prepareCategoryList() {
    const that = this;
    const data = this.getLocalStorageData();

    const motor = ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV","NVFR"];
    const heli = ["ALW","HPL","MET","COM","PFH","OPR","FPP","AGK","NAV","NVFR"];
    const segel = ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV"];
    const ballon = ["ALW","HPL","MET","COM","PFB","OPR","FPP","AGK","NAV"];
    const DEFAULT_CATEGORIES = ["ALW","HPL","MET","COM","PFA","PFH","PFB","OPR","FPP","AGK","NAV","NVFR"];

    let is_motor = data.motor,
        is_heli = data.heli,
        is_segel = data.segel,
        is_ballon = data.ballon;
    
    let categoriesToDisplay = [];

    if (this.props.isHomeworkAndHasID || this.props.isExamAndHasID) {
      // if categories got passed by homework, prefer homework categories
      const homeworkCategories = this.props.homeworkCategories;

      if (homeworkCategories) {
        // collect and find questions from flightschool
        const flightschoolCategoryCount = this.getFlightschoolQuestionCountPerCategory();
        _.each(homeworkCategories, function (category) {
          const count = flightschoolCategoryCount[category.id];
          if (count) {
  
            // TODO: check if it's a default category -> don't need it anymore
            const isADefaultCategory = _.find(DEFAULT_CATEGORIES, function(cat) { return cat === category.id });
            if (isADefaultCategory) {
              categoriesToDisplay.push(category);
            } else {
              categoriesToDisplay.push({
                "category": "FS_" + category.id,
                "count": count,
              });
            }
          } else {
            categoriesToDisplay.push(category);
          }
        });
      }

    } else {
      // just render all available categories
      if (is_motor) categoriesToDisplay = _.union(categoriesToDisplay, motor);
      if (is_heli) categoriesToDisplay = _.union(categoriesToDisplay, heli);
      if (is_segel) categoriesToDisplay = _.union(categoriesToDisplay, segel);
      if (is_ballon) categoriesToDisplay = _.union(categoriesToDisplay, ballon);
  
      var { flightschool_categories, radio_categories } = this.props;
  
      if (flightschool_categories) {
        const flightschoolCategoryCount = this.getFlightschoolQuestionCountPerCategory();
        _.each(flightschool_categories, function (category) {
          const count = flightschoolCategoryCount[category.id];
          if (count) {
  
            // check if it's a default category -> don't need it anymore
            const isADefaultCategory = _.find(categoriesToDisplay, function(cat) {
              return cat === category.id;
            });
            if (isADefaultCategory) return;
  
            categoriesToDisplay.push({
              "category": "FS_" + category.id,
              "count": count,
            });
          }
        });
      }
      
      if (radio_categories) {
        const radioCategoryCount = this.getRadioQuestionCountPerCategory(radio_categories);
        _.each(radio_categories, function (category) {
          const count = radioCategoryCount[category.category];
          if (count) {
            categoriesToDisplay.push({
              "category": "RADIO_" + category.category,
              "count": count,
            });
          }
        });
      }
    }
    
    const examData = this.getSessionExam();

    const list = categoriesToDisplay.map(key=>{

      let defaultQuestionCount = that.props.homeworkQuestionCount || parseInt(that.props.user && that.props.user.totalCount) || 16;

      if (key.category) {

        // for radio questions there may be custom count settings
        var isRadio = key.category.split("_")[0] === "RADIO";
        if (isRadio) {
          defaultQuestionCount = that.props.homeworkQuestionCount || parseInt(that.props.user && that.props.user.totalCountRadio) || 16;
        }

        const categoryQuestionCount = key.count;
        if (categoryQuestionCount < defaultQuestionCount) defaultQuestionCount = categoryQuestionCount;
        key = key.category;
      }

      // if key is in localstorage of exam -> disable and make it OK
      const sessionExam = that.getSessionExam();
      let examIsDone = _.find(sessionExam, function (entry, k) { return k === key });

      let status = undefined;
      let answeredQuestionsLenght = 0;
      
      // TODO max 16 questions
      const sum = defaultQuestionCount;
      if (examIsDone) {
        answeredQuestionsLenght = examIsDone.wrong.length + examIsDone.right.length;
      }

      // given question sum has to be equal to the full sum length
      if (_.keys(examData).length > 0 && answeredQuestionsLenght === sum) {
        this.completed = true;
      } else {
        examIsDone = false;
      }

      if (examIsDone) {
        const right = examIsDone.right;
        const earned = right.length;
        let perc= ((earned/sum) * 100);
        perc = Math.floor(perc);

        const percentage = this.props.homeworkExamPercentage || 75;

        status = "passed";
        if (perc < percentage) {
          status = "not_passed";
        }
      } else if (answeredQuestionsLenght > 0) {
        status = "in_progress";
      }

      return (
        <div key={key} className={`fbc category-list-item ${that.getCategoryStatus(status)}`} id={`category-${key}`}>
          <div className="fbf category-status-container">
            {
              this.props.printNewExam && this.props.isATO ?
                <div key={key}  className="fbl">
                  <Checkbox onChange={() => that.onSelectCategoryForPrintExam(key)} label={that.getTranslation(key)} defaultChecked={this.isDefaultTopicForPrintExam(key)} />
                </div>
              :
                <div key={key} onClick={() => that.onSelectCategory(key, examIsDone)} id={key} className="fbl">
                  {that.getTranslation(key)}
                </div>
            }
          </div>
        </div>
      );
    });

    return list;
  }

  getCategoryStatus(status) {
    if (this.props.printNewExam && this.props.isATO) return "";
    switch (status) {
      case "passed":
        return "green-background";
      case "not_passed":
        return "red-background";
      case "in_progress":
        return "orange-background";
      default: 
        return "";
    }
  }

  getLocalStorage() {
    return JSON.parse(localStorage.getItem('ppltrainer'));
  }

  render() {
    if (this.props.questions) {
      return (
        <QuestionContent
          questions={this.props.questions}
          categories={this.props.categories}
          settings={this.props.settings}
          mode={this.props.mode}
          backToSimulationOverview={this.props.backToSimulationOverview}
        />
      )
    }
    const categoryList = this.prepareCategoryList();
    const completed = this.completed;
    return (
      <div className="Categories Categories-Simulation">
        <div className="quiz-question-header">
          <label>{ this.props.isHomeworkAndHasID ? i18n("homework_simulation") : i18n("ExamSimulation") }</label>
        </div>
        {
          this.props.printNewExam && this.props.isATO ?
            <div className="extra-print-new-exam-container">{i18n("chooseAllTopicsForPrintedExam")} </div>
          : null
        }
        { categoryList }
        <div className="submit-exam">
          {
            this.props.printNewExam && this.props.isATO ?
              <button className="btn-primary printExamBtn" onClick={e => this.createExam()} ><Icon name="print" /> {i18n("printExamtoPDF")} <br/> <small className="lowercasedFont">{i18n("forExamsOnPaper")}</small></button>
            :
              <div>
                { !completed ? <div className="submit-exam-info">{i18n("not_completed_yet")}</div> : null }
                <button className="btn-primary" disabled={!completed} onClick={e => this.props.sendExam()} >{ this.props.isHomeworkAndHasID ? i18n("finish_homework") : i18n("sendExam") }</button><br/>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default SimulationOverview;
