import axios from 'axios';
import _ from 'underscore';

  class API {

    static DEFAULT_USER_URL = process.env.DEFAULT_USER_URL

    static getUserData() {
      return new Promise((resolve, reject) => {
        const endpoint = API.DEFAULT_USER_URL + "/user";
        axios.get(endpoint)
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((error) => {
            console.log(error);
            resolve(false, error);
          });
      }); 
    }

    static async getAnswerAssistance(data) {
      const endpoint = API.DEFAULT_USER_URL + "/answerQuestion";
      try {
        const { id, question, answer, language } = data;
        const lang = language || document.documentElement.lang;
        const request = { "id": id, "question": question, "answer": answer, "language": lang };
        const response = await axios.post(endpoint, request);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    static async feedbackAIAssistance(data) {
      const endpoint = API.DEFAULT_USER_URL + "/assistanceAnswerFeedback";
      try {
        const request = data;
        const response = await axios.post(endpoint, request);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    static cleanup() {
      return new Promise((resolve, reject) => {
        const endpoint = API.DEFAULT_USER_URL + "/user/cleanup";
        axios.post(endpoint, {})
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((error) => {
            console.log(error);
            resolve(false, error);
          });
      }); 
    }

    static syncSettings(callback) {
      const data = API.collectLocalStorageSettings();
      const endpoint = API.DEFAULT_USER_URL + "/user/sync";
      axios.post(endpoint, {
        settings: data,
        version: "1.0.0",
        lastSynced: new Date(),
      })
      .then((response) => {
        if (callback && _.isFunction(callback)) {
          callback(response);
        }
      })
      .catch((error) => {
        console.error(error);
        if (callback && _.isFunction(callback)) {
          callback();
        }
      })
    }

    static getFinishedExams() {
      return new Promise((resolve, reject) => {
        const endpoint = API.DEFAULT_USER_URL + "/finishedExams";
        axios.get(endpoint)
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((error) => {
            console.log(error);
            resolve(false, error);
          });
      }); 
    }

    static addFinishedExams(data) {
      const endpoint = API.DEFAULT_USER_URL + "/finishedExams";
      return new Promise((resolve, reject) => {
        axios.post(endpoint, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    static getUserHomework() {
      return new Promise((resolve, reject) => {
        const endpoint = API.DEFAULT_USER_URL + "/homework";
        axios.get(endpoint)
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((error) => {
            console.log(error);
            resolve(false, error);
          });
      }); 
    }

    static addHomework(data) {
      const endpoint = API.DEFAULT_USER_URL + "/homework";
      return new Promise((resolve, reject) => {
        axios.post(endpoint, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    static syncExams() {
      const data = API.collectLocalStorageExams();
      const endpoint = API.DEFAULT_USER_URL + "/user/exams";
      return new Promise((resolve, reject) => {
        axios.post(endpoint, {
          exams: data,
          version: "1.0.0",
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
      });
    }

    static getUserExams() {
      return new Promise((resolve, reject) => {
        const endpoint = API.DEFAULT_USER_URL + "/user/exams";
        axios.get(endpoint)
          .then((response) => {
            resolve(response && response.data);
          })
          .catch((error) => {
            resolve(false, error);
          });
      }); 
    }

    static collectLocalStorageExams() {
      let exams = localStorage.getItem("ppltrainer_exam_storage");
      if (exams === '') {
        exams = '[]';
      }
      return exams;
    }
  
    static collectLocalStorageSettings() {
      var data = {};
  
      const default_settings = JSON.parse(localStorage.getItem('ppltrainer')) || {};
      const ppltrainer_settings = JSON.parse(localStorage.getItem('ppltrainer_settings')) || {};
      const favorite_questions_collection = JSON.parse(localStorage.getItem('favorite_questions_collection')) || {};
      const preferredLanguage = localStorage.getItem('preferredLanguage') || "de";
  
      data["ppltrainer"] = default_settings;
      data["ppltrainer_settings"] = ppltrainer_settings;
      data["favorite_questions_collection"] = favorite_questions_collection;
      data["preferredLanguage"] = preferredLanguage;
  
      data["categories"] = { builtin: {}, radio: {}, older: {}, flightschool: {} };

      // default/builtin categories
      const categories = ["AGK","PFA","PFB","PFH","MET","NAV","COM","ALW","HPL","OPR","FPP"];
      _.each(categories, function(category) {
        const categoryData = localStorage.getItem(category);
        if (categoryData) {
          data["categories"].builtin[category] = JSON.parse(categoryData) || [];
        }
      });

      // radio questions
      const radioCategories = ["RADIO_recht","RADIO_sonderbestimmungen","RADIO_technik", "RADIO_AZF", "RADIO_BZF1_BZF2"];
      _.each(radioCategories, function(category) {
        const categoryData = localStorage.getItem(category);
        if (categoryData) {
          data["categories"].radio[category] = JSON.parse(categoryData) || [];
        }
      });

      // older questions
      const olderCategories = ["OLDER_AGK","OLDER_PFA","OLDER_PFB","OLDER_PFH","OLDER_MET","OLDER_NAV","OLDER_COM","OLDER_ALW","OLDER_HPL","OLDER_OPR","OLDER_FPP","OLDER_NVFR"];
      _.each(olderCategories, function(category) {
        const categoryData = localStorage.getItem(category);
        if (categoryData) {
          data["categories"].older[category] = JSON.parse(categoryData) || [];
        }
      });

      // TODO flightschool questions
      const flightschoolQuestions = Object.keys(localStorage).filter( (key)=> key.startsWith("FS_"));
      _.each(flightschoolQuestions, function(category) {
        const categoryData = localStorage.getItem(category);
        if (categoryData) {
          data["categories"].flightschool[category] = JSON.parse(categoryData) || [];
        }
      });

      return data;
    }


  }
  
  export default API;