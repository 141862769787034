import React, { Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import i18n from '../../../utils/i18n';

class ATOFormular extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.update = this.update.bind(this);
  }

  update(options, data) {
    let name = options.target.name || (data && data.name);
    let value = options.target.value || (data && data.value);
    const obj = {};
    obj[name] = value;
    this.setState(obj, () => {
      this.props.updateContent({data: this.state, id: 'account'});
    });
  }

  render() {
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_ato")}</Modal.Header>
      <Modal.Description>
      </Modal.Description>
      <div className='mm-form-container'>
        <form>
            <div className='mm-form-item'>
              <Icon name="user" title={i18n("email")} />
              <input type="email" id="email" placeholder={i18n("email")} name="email" onChange={(options) => this.update(options)} />
            </div>
          </form>
      </div>  
    </Modal.Content>
  }
}

export default ATOFormular;
