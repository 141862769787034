import React, { Component } from 'react';
import _ from 'underscore';
import { Checkbox, Icon } from 'semantic-ui-react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import i18n from "../utils/i18n";
import API from '../utils/resources/api.js';
import utils from '../utils/utils';

import './css/categories.css';

class Categories extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};

    this.shouldCloseAdditionalInformation = this.shouldCloseAdditionalInformation.bind(this);
    this.isAdditionalInformationsVisible = this.isAdditionalInformationsVisible.bind(this);
    this.openAdditionalInformation = this.openAdditionalInformation.bind(this);
    this.prepareCategoryList = this.prepareCategoryList.bind(this);
    this.resetLocalStorageForKey = this.resetLocalStorageForKey.bind(this);
    this.getQuestionCountFromProps = this.getQuestionCountFromProps.bind(this);
    this.getQuestionCountFromLocalStorage = this.getQuestionCountFromLocalStorage.bind(this);
    this.flightschoolQuestions = this.flightschoolQuestions.bind(this);
    this.flightschoolScenarios = this.flightschoolScenarios.bind(this);
    this.radioQuestions = this.radioQuestions.bind(this);
    this.getTranslation = this.getTranslation.bind(this);
    this.getTranslationFromFlightSchoolCategories = this.getTranslationFromFlightSchoolCategories.bind(this);
    this.prepareFlightschoolCategoryList = this.prepareFlightschoolCategoryList.bind(this);
    this.prepareFlightschoolScenarios = this.prepareFlightschoolScenarios.bind(this);
    this.prepareRadioQuestionList = this.prepareRadioQuestionList.bind(this);
    this.prepareOlderQuestionsList = this.prepareOlderQuestionsList.bind(this);
    this.getQuestionCountFromFlightschool = this.getQuestionCountFromFlightschool.bind(this);
    this.getQuestionCountFromRadio = this.getQuestionCountFromRadio.bind(this);
    this.getQuestionCountFromOlderQuestions = this.getQuestionCountFromOlderQuestions.bind(this);
    this.preparedQuestionObjectFromAdditionalQuestions = this.preparedQuestionObjectFromAdditionalQuestions.bind(this);
  }

  getLocalStorageData() {
    let data = JSON.parse(localStorage.getItem('ppltrainer'));
    if (!data) {
      data = {
        motor: true,
        heli: false,
        segel: false,
        ballon: false,
        country: 'AT',
        language: "de",
      }
    }
    return data;
  }

  getPathColor(percent) {
    if (percent < 5) return "#d9534f"; // red
    if (percent < 100) return "#f0ac4d"; // orange
    return "#5cb85c"; // green
  }

  getQuestionCountFromFlightschool(category) {
    // get questions from questions.flightschool_questions
    const questionDB = this.props.questions["flightschool_questions"][category];
    const categoryQuestions = this.preparedQuestionObjectFromAdditionalQuestions(questionDB.data);
    return categoryQuestions.data.length;
  }

  getQuestionCountFromRadio(category) {
    // get questions from questions.flightschool_questions
    const questionDB = this.props.questions["radio_questions"][category];
    const categoryQuestions = this.preparedQuestionObjectFromAdditionalQuestions(questionDB.data);
    return categoryQuestions.data.length;
  }

  getQuestionCountFromOlderQuestions(category, data) {
    // get questions from questions.older_questions
    const questionDB = this.props.questions["older_questions"][category];
    const categoryQuestions = this.preparedQuestionObjectFromAdditionalQuestions(questionDB.data);
    return categoryQuestions.data.length;
  }

  preparedQuestionObjectFromAdditionalQuestions(data) {
    return {
      "data": _.map(data, function (question) {
        return { key: question.key };
      })
    };
  }

  getQuestionCountFromProps(category) {
    // en and de questions are the same so we can get "de" questions to calculate the list of needed categories
    const questionDB = this.props.questions.data[category];
    if (!questionDB) {
      // exclude categories without questions
      return null;
    }

    const categoryQuestions = this.preparedQuestionObject(category, questionDB.data);
    return categoryQuestions.data.length;
  }

  preparedQuestionObject(category, data) {
    let unionQuestionDatabaseList = utils.getRequestedDatabaseQuestions(data[0]);
    return {
      "data": _.map(
        _.filter(data, function (q) {
          const list = _.find(unionQuestionDatabaseList, function (cat) { return q[cat] && q[cat].length });
          return list;
        }),
        function (question) {
          const entry = {};
          entry.key = category + "_" + question["Question ID"];
          entry.originalKey = question["Question ID"];
          return entry;
        })
    };
  }

  getQuestionCountFromLocalStorage(key) {
    let data = JSON.parse(localStorage.getItem(key));
    return (data && data.length) || 0;
  }

  getCategoriesToDisplay(options) {
    const data = this.getLocalStorageData();

    const motor = ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV","NVFR"];
    const heli = ["ALW","HPL","MET","COM","PFH","OPR","FPP","AGK","NAV","NVFR"];
    const segel = ["ALW","HPL","MET","COM","PFA","OPR","FPP","AGK","NAV"];
    const ballon = ["ALW","HPL","MET","COM","PFB","OPR","FPP","AGK","NAV"];

    let is_motor = data.motor,
        is_heli = data.heli,
        is_segel = data.segel,
        is_ballon = data.ballon;
    
    let categoriesToDisplay = [];
    if (is_motor) categoriesToDisplay = _.union(categoriesToDisplay, motor);
    if (is_heli) categoriesToDisplay = _.union(categoriesToDisplay, heli);
    if (is_segel) categoriesToDisplay = _.union(categoriesToDisplay, segel);
    if (is_ballon) categoriesToDisplay = _.union(categoriesToDisplay, ballon);

    if (options && options.includeFlightSchoolCategories) {
      var { flightschool_categories } = this.props;
      if (flightschool_categories) {
        _.each(flightschool_categories, function (category) {
          categoriesToDisplay.push(category.id);
        });
      }
    }

    if (options && options.includeRadioCategories) {
      var { radio_categories } = this.props;
      if (radio_categories) {
        _.each(radio_categories, function (category) {
          categoriesToDisplay.push(category.id);
        });
      }
    }

    return categoriesToDisplay;
  }

  prepareCategoryList() {
    const that = this;
    const categoriesToDisplay = this.getCategoriesToDisplay();
    var preparedCategoryList = categoriesToDisplay.map(key=>{
      const questionCount = this.getQuestionCountFromProps(key);
      if (!questionCount) {
        // exclude categories without questions
        return false;
      }
      const questionsCompletedCount = this.getQuestionCountFromLocalStorage(key);
      const questionsCompletedCountInPercentage = parseInt((questionsCompletedCount/questionCount) * 100);
      const pathColor = this.getPathColor(questionsCompletedCountInPercentage);
      return that.renderCategoryItem(key, questionCount, questionsCompletedCount, questionsCompletedCountInPercentage, pathColor, null);
    });
    // return only categories with questions
    return preparedCategoryList.filter(item => item !== null && item !== false);
  }

  renderCategoryItem(key, questionCount, questionsCompletedCount, questionsCompletedCountInPercentage, pathColor, FSKey) {
    const translationKey = key;
    key = FSKey || key;
    return (
      <div key={key} className="fbc category-list-item" id={`category-${key}`}>
        <Checkbox key={key} onChange={this.props.onChange} id={key} className="fbl" label={this.getTranslation(translationKey)} />
        <div className="fbf category-status-container">
          <CircularProgressbarWithChildren
            value={questionsCompletedCountInPercentage}
            //text={questionsCompletedCountInPercentage}
            strokeWidth="15"
            styles={buildStyles({ textColor: pathColor, pathColor: pathColor, trailColor: "#e8e8e8" })}
            onClick={e => this.openAdditionalInformation(key)}
          >
            <div className="placeholder-item" onClick={e => this.openAdditionalInformation(key)}></div>
            <div className='more-icon-placeholder' onClick={e => this.openAdditionalInformation(key)}>...</div>
            <div className={`category-overlay-container ${this.isAdditionalInformationsVisible(key) ? 'show' : 'hide'}`}>
              <small>
                <span>{questionsCompletedCount}/{questionCount} {i18n("questions")} ({questionsCompletedCountInPercentage}%)</span>
                <span> | </span>
                <div className="resetCount" onClick={e => this.resetLocalStorageForKey(key)}>{i18n("reset")}</div>
              </small>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    );
  }

  prepareFlightschoolCategoryList() {
    const that = this;
    const categoriesToDisplay = [];
    const availableCategoriesToDisplay = this.getCategoriesToDisplay({ includeFlightSchoolCategories: true });
    const flightschoolCategories = _.keys(this.flightschoolQuestions());
    // only categories which occur in flightschool categories + are allowed to display by settings
    _.each(flightschoolCategories, function (category) {
      if (availableCategoriesToDisplay.indexOf(category) >= 0) {
        categoriesToDisplay.push(category);
      }
    });
    if (!categoriesToDisplay.length) return <span className="flightschool-no-questions">{i18n("no_public_questions_available")}</span>
    return categoriesToDisplay.map(key=>{
      const questionCount = this.getQuestionCountFromFlightschool(key);
      // from now on work with flightschool key
      const questionsCompletedCount = this.getQuestionCountFromLocalStorage("FS_"+key);
      const questionsCompletedCountInPercentage = parseInt((questionsCompletedCount/questionCount) * 100);
      const pathColor = this.getPathColor(questionsCompletedCountInPercentage);
      return that.renderCategoryItem(key, questionCount, questionsCompletedCount, questionsCompletedCountInPercentage, pathColor, "FS_"+key);
    });
  }

  prepareRadioQuestionList() {
    const that = this;
    const categoriesToDisplay = [];
    const availableCategoriesToDisplay = this.getCategoriesToDisplay({ includeRadioCategories: true });
    const radioCategories = _.keys(this.radioQuestions());
    // only categories which occur in flightschool categories + are allowed to display by settings
    _.each(radioCategories, function (category) {
      if (availableCategoriesToDisplay.indexOf("RADIO_" + category) >= 0) {
        categoriesToDisplay.push(category);
      }
    });
    if (!categoriesToDisplay.length) return <span className="radio-no-questions">{i18n("no_public_questions_available")}</span>
    return categoriesToDisplay.map(key=>{
      const questionCount = this.getQuestionCountFromRadio(key);
      // from now on work with flightschool key
      const questionsCompletedCount = this.getQuestionCountFromLocalStorage("RADIO_"+key);
      const questionsCompletedCountInPercentage = parseInt((questionsCompletedCount/questionCount) * 100);
      const pathColor = this.getPathColor(questionsCompletedCountInPercentage);
      return that.renderCategoryItem(key, questionCount, questionsCompletedCount, questionsCompletedCountInPercentage, pathColor, "RADIO_"+key);
    });
  }

  prepareOlderQuestionsList() {
    if (this.props.questions.older_questions) {
      const that = this;
      const categoriesToDisplay = _.keys(this.props.questions.older_questions);

      const categories = [];
      _.each(this.getCategoriesToDisplay(), function (el) { 
        if (categoriesToDisplay.indexOf(el) >= 0) {
          categories.push(el);
        }
      });

      let categorieList = _.filter(categories, function (key) {
        return that.getQuestionCountFromOlderQuestions(key) > 0;
      });

      return categorieList.map(key=>{
        const questionCount = this.getQuestionCountFromOlderQuestions(key);
        const questionsCompletedCount = this.getQuestionCountFromLocalStorage("OLDER_"+key);
        const questionsCompletedCountInPercentage = parseInt((questionsCompletedCount/questionCount) * 100);
        const pathColor = this.getPathColor(questionsCompletedCountInPercentage);
        return that.renderCategoryItem(key, questionCount, questionsCompletedCount, questionsCompletedCountInPercentage, pathColor, "OLDER_"+key);
      });
    }
  }

  prepareFlightschoolScenarios() {
    const that = this;
    const flightschoolScenarios = this.flightschoolScenarios();
    if (!flightschoolScenarios) return null;
    return <div className="fbc category-list-item scenario-bubble-container">
      {
        Object.keys(flightschoolScenarios).map(id=>{
          const scenario = flightschoolScenarios[id];
          const scenarioQuestionCount = scenario.questions.length;
          return (
            <div 
              key={id} 
              className='scenario-bubble-item'
              onClick={() => that.props.onSelectScenario(scenario)}
            >
              {scenario.title} <span>({scenarioQuestionCount})</span>
            </div>
          );
        })
      }
    </div>
  }

  resetLocalStorageForKey(key) {
    localStorage.removeItem(key);
    API.syncSettings();
    this.setState({
      hoveredElement: null,
    }, this.forceUpdate());
  }

  shouldCloseAdditionalInformation(key, options) {
    // check if clicked inside of category-status-container
    const parent = document.getElementById("category-" + key);
    let parentElement = parent && parent.querySelector('.category-status-container');
    if (!parentElement) return;
    if (parentElement.contains(options.target)) {
      // check if element is reset button -> allow this
      if (options.target.className !== "resetCount") this.setState({ hoveredElement: key });
    } else {
      this.setState({ hoveredElement: null });
      window.removeEventListener("click", e => this.shouldCloseAdditionalInformation(key, e));
    }
  }

  openAdditionalInformation(key) {
    this.setState({ hoveredElement: key });
    window.addEventListener("click", options => this.shouldCloseAdditionalInformation(key, options));
  }

  isAdditionalInformationsVisible(key) {
    if (key === this.state.hoveredElement) return true;
    return false;
  }

  getTranslationFromFlightSchoolCategories(key) {
    var { flightschool_categories } = this.props;
    var item = _.find(flightschool_categories, function (category) {
      return category.id === key; 
    });
    return (item && item.category) || false;
  }

  getTranslationForRadioCategories(key) {
    switch(key) {
      case "DE_bzf1":
        return "BZF I";
      case "DE_azf":
        return "AZF";
      case "DE_bzf2":
        return "BZF II";
      case "AT_bfz":
        return "BFZ";
      case "AT_efz":
        return "EFZ";
      case "AT_afz":
        return "AFZ";
      default:
        return false;
      }
  }

  getTranslation(value) {
    var preTranslation = i18n(value);
    if (preTranslation) return preTranslation;
    var key = value.split("_")[1] || value;
    var translatedValue = i18n(value);
    return  translatedValue || this.getTranslationFromFlightSchoolCategories(key) || this.getTranslationForRadioCategories(key) || key;
  }

  getSelectedCategoryItems() {
    const items = [];

    const data = this.getLocalStorageData();
    if (data.motor) items.push("PPL(A)");
    if (data.heli) items.push("PPL(H)");
    if (data.segel) items.push("SPL");
    if (data.ballon) items.push("BPL");

    return items;
  }

  flightschoolQuestions() {
    return this.props.enableFlightschoolContent && this.props.questions.flightschool_questions;
  }

  flightschoolScenarios() {
    return this.props.enableFlightschoolContent && this.props.questions.flightschool_scenarios;
  }

  radioQuestions() {
    return this.props.enableRadioQuestions && this.props.questions.radio_questions;
  }

  render() {
    const SelectedCategoryItems = this.getSelectedCategoryItems().join(", ");
    const CategoryList = this.prepareCategoryList();
    const OlderQuestionsList = this.prepareOlderQuestionsList();
    const RadioQuestionsList = this.prepareRadioQuestionList();
    const FlightschoolCategoryList = this.prepareFlightschoolCategoryList();
    const FlightschoolScenarios = this.prepareFlightschoolScenarios();

    return (
      <div className="Categories">
        <div className="bold big">
          { SelectedCategoryItems }
          <div className='additional-questions-hint'>{i18n("main_ecqb_questions_hint")}</div>
        </div>

        { CategoryList }

        {
          <div className="mt-20">
            <div className="bold big">
              {i18n("additional_questions")} <Icon name="info circle" title={i18n("additional_questions_icon_hint")} className="curser-default additional_question_icon" />
              <div className='additional-questions-hint'>{i18n("additional_questions_icon_hint")}</div>
            </div>
            { OlderQuestionsList }
          </div>
        }

        {
          this.radioQuestions() ?
            <div className="mt-20">
              <span className="bold big">{i18n("radio_questions")}</span>
              { RadioQuestionsList }
            </div>
          : null
        }
        
        {
          this.flightschoolQuestions() ?
            <div className="mt-20">
              <span className="bold big">{i18n("flightschool_questions")}</span>
              <div className='additional-questions-hint'>{i18n("flightschool_questions_subtitle")}</div>
              { FlightschoolCategoryList }
            </div>
          : null
        }

{
          this.flightschoolScenarios() ?
            <div className="mt-20">
              <span className="bold big">{i18n("scenarios_of_my_flightschool")}</span>
              <div className='additional-questions-hint'>{i18n("scenarios_of_my_flightschool_subtitle")}</div>
              { FlightschoolScenarios }
            </div>
          : null
        }

      </div>
    );
  }
}

export default Categories;
