import React, { Component } from 'react';
import { Icon, Modal, Header, Button, Radio } from 'semantic-ui-react';
import _ from 'underscore';
import moment from 'moment';
import 'moment/min/locales';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import i18n from "../utils/i18n";
import API from '../utils/resources/api.js';
import SavedQuestionOverlay from './SavedQuestionOverlay'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSuccessfullySent: false,
      checkedTrainingsModel: this.props.settings["noConfirmationOnClickingAnswer"],
      showQuestionsOfFlightschool: this.props.settings["showQuestionsFromFlightschool"],
      openSavedQuestion: null,
    }

    moment.locale(document.documentElement.lang || "en");

    this.toggleTrainingsModel = this.toggleTrainingsModel.bind(this);
    this.getPreviousExams = this.getPreviousExams.bind(this);
    this.getFavoriteQuestions = this.getFavoriteQuestions.bind(this);
    this.toggleShowQuestionsOfFlightschoolModel = this.toggleShowQuestionsOfFlightschoolModel.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.setFavoriteQuesionsLocalStorage = this.setFavoriteQuesionsLocalStorage.bind(this);
    this.removeFavoriteQuestion = this.removeFavoriteQuestion.bind(this);
    this.sendItem = this.sendItem.bind(this);
    this.onModalOpen = this.onModalOpen.bind(this);
    this.examsAreAvailable = this.examsAreAvailable.bind(this);
    this.savedQuestionsAreAvailable = this.savedQuestionsAreAvailable.bind(this);
  }

  componentDidUpdate() {
    // update setting from ato.ppltrainer
    if (this.state.showQuestionsOfFlightschool !== this.props.settings["showQuestionsFromFlightschool"]) {
      this.setState({ showQuestionsOfFlightschool: this.props.settings["showQuestionsFromFlightschool"] });
    }
    if (this.state.checkedTrainingsModel !== this.props.settings["noConfirmationOnClickingAnswer"]) {
      this.setState({ checkedTrainingsModel: this.props.settings["noConfirmationOnClickingAnswer"] });
    }
  }

  toggleTrainingsModel() {
    this.setState({
      checkedTrainingsModel: !this.state.checkedTrainingsModel
    }, () => {
      this.props.onToggleTrainingsModel(this.state.checkedTrainingsModel);
      this.sync();
    });
  }

  toggleShowQuestionsOfFlightschoolModel() {
    this.setState({
      showQuestionsOfFlightschool: !this.state.showQuestionsOfFlightschool
    }, () => {
      this.props.onToggleShowQuestionsOfFlightschool(this.state.showQuestionsOfFlightschool);
      this.sync();
    });
  }

  sync() {
    const that = this;
    API.syncSettings(function(result) {
      localStorage.setItem("last_synchronized_data", result && result.data && result.data.lastSynced);
      that.forceUpdate();
    });
  }

  syncExams() {
    API.syncExams();
  }

  getExamLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem("ppltrainer_exam_storage"));
    } catch(e) {
      return [];
    }
  }

  getSavedQuestionsLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('favorite_questions_collection'));
    } catch(e) {
      return {};
    }
  }

  setExamLocalStorage(data) {
    localStorage.setItem("ppltrainer_exam_storage", JSON.stringify(data));
    this.syncExams();
  }

  setFavoriteQuesionsLocalStorage(data) {
    localStorage.setItem("favorite_questions_collection", JSON.stringify(data));
    this.sync();
  }

  downloadItem(index) {
    const exams = this.getExamLocalStorage();
    const selectedExam = exams[index];
    if (!selectedExam) return;
    pdfMake.createPdf(selectedExam.dd).download("ppltrainer-exam-"+ selectedExam.date +".pdf");
  }

  removeItem(index) {
    if (window.confirm(i18n("confirm_delete_exam"))) {
      const exams = this.getExamLocalStorage();
      exams.splice(index, 1);
      this.setExamLocalStorage(exams);
      this.forceUpdate();
    }
  }

  removeFavoriteQuestion(key) {
    const favoriteQuestions = this.getSavedQuestionsLocalStorage();
    delete favoriteQuestions[key];
    this.setFavoriteQuesionsLocalStorage(favoriteQuestions);
    this.forceUpdate();
  }

  sendItem(index) {
    const that = this;
    const exams = this.getExamLocalStorage();
    const selectedExam = exams[index];
    if (!selectedExam) return;

    try {
      // send email
      const file = pdfMake.createPdf(selectedExam.dd);
      file.getBlob((blob) => {
        blob.lastModifiedDate = new Date();
        blob.name = "ppltrainer-exam-"+ selectedExam.date +".pdf";

        const idToken = JSON.parse(localStorage.getItem('okta-token-storage'));
        const {email, firstname, lastname} = idToken.idToken.claims;

        var fd = new FormData();
        fd.append('username', firstname + " " + lastname);
        fd.append('email', email);
        fd.append('filename', "ppltrainer-exam-"+ selectedExam.date +".pdf");
        fd.append('date', selectedExam.date);
        //fd.append('file', blob);
        file.getBase64((data) => {
          fd.append("data", data);
          var request = new XMLHttpRequest();
          request.open("POST", "https://api.ppltrainer.at/email/sendPDF.php");
          request.send(fd);
          that.setState({ emailSuccessfullySent: index });
          window.setTimeout(function() {
            that.setState({ emailSuccessfullySent: false });
          }, 3000);
        });
      });
    } catch (e) {
      console.warn("Cannot send email. Please contact the administrator at info@ppltrainer.at");
    }
  }

  examsAreAvailable() {
    const examsLoaded = this.state.examsLoadingFinished;
    const exams = this.getExamLocalStorage();
    return examsLoaded && exams && exams.length;
  }

  savedQuestionsAreAvailable() {
    const favoriteQuestions = this.getSavedQuestionsLocalStorage();
    return !_.isEmpty(favoriteQuestions);
  }

  getUserExams() {
    API.getUserExams().then((result, error) => {
      let exams = result.exams;
      if (_.isUndefined(exams)) {
        // initial sync
        exams = localStorage.getItem("ppltrainer_exam_storage") || "[]";
        API.syncExams();
      }
      localStorage.setItem("ppltrainer_exam_storage", exams);
      this.setState({ examsLoadingFinished: true });
      this.forceUpdate();
    })
    .catch(() => {
      this.setState({ examsLoadingFinished: true });
    });
  }

  getPathColor(percent) {
    if (percent < 75) return "#d9534f"; // red
    return "#5cb85c"; // green
  }

  getBackgroundColorClassForExams(percent) {
    if (percent < 75) return "bg-red"; // red
    return "bg-green"; // green
  }

  getPercentValueOfExam(eaxm) {
    const categories = eaxm.dd.content[5].table.body;
    let count = 0;
    _.each(categories, function(category) {
      let value = category[2];
      value = value.split("%")[0];
      count += parseFloat(value);
    });
    return parseInt(count / categories.length);
  }

  getPreviousExams() {
    const that = this;
    const exams = this.getExamLocalStorage();
    if (exams) {
      return _.map(exams, function(exam, numb) {
        const { firstname, lastname, date } = exam;
        const value = that.getPercentValueOfExam(exam);
        return (
          <div className={`exam-container ${that.getBackgroundColorClassForExams(value)}`} key={numb}>
            <div className='fbf'>
            <CircularProgressbarWithChildren
              className='circle-exams'
              value={value}
              text={value}
              strokeWidth="15"
              styles={buildStyles({ textColor: that.getPathColor(value), pathColor: that.getPathColor(value), trailColor: "#e4e4e4" })}
              onClick={() => that.downloadItem(numb)}
            ></CircularProgressbarWithChildren>
            </div>
            <div className='fbl'>
              <div>{firstname} {lastname}, <b>{date}</b></div>
              <div className='exam-container-actions'>
                <div className={"exam-action-item send-as-email " + (that.state.emailSuccessfullySent === numb ? 'email-sent' : '')} onClick={() => that.sendItem(numb) } ><Icon disabled name='mail' /> {i18n("email")} <Icon name="check" className="hidden-until-sent" /></div>
                <div className="exam-action-item" onClick={() => that.downloadItem(numb) }><Icon disabled name='download' /> {i18n("download")}</div>
                <div className="exam-action-item" onClick={() => that.removeItem(numb) }><Icon disabled name='delete' /> {i18n("delete")}</div>
              </div>
            </div>
          </div>
        )
      });
    } else {
      return;
    }    
  }

  openSavedQuestion(key) {
    if (!key) return;

    const keySegments = key.split("_");
    let questionKey = '';
    let category = '';
    let dataset = '';

    switch(keySegments[0]) {
      case "OLDER": 
        dataset = "older_questions";
        category = keySegments[1];
        questionKey = keySegments[2];
        break;
      case "FS": 
        dataset = "flightschool_questions";
        category = keySegments[1];
        questionKey = keySegments[2];
        break;
      case "AT": 
        dataset = "radio_questions";
        category = keySegments[1];
        questionKey = keySegments[1];
        break;
      case "DE": 
        dataset = "radio_questions";
        category = keySegments[1];
        questionKey = keySegments[1];
        break;
      default:
        dataset = "data";
        category = keySegments[0];
        questionKey = keySegments[1];
    }

    const { questions } = this.props;
    const questionObject = questions[dataset] &&
                     questions[dataset][category] &&
                     questions[dataset][category].data;
                     
    const question = _.find(questionObject, function (item) {
      return item.key === key || item.key === questionKey || item["Question ID"] === questionKey;
    });

    this.setState({ openSavedQuestion: question });
  }

  getFavoriteQuestions() {
    const that = this;
    const favoriteQuestions = this.getSavedQuestionsLocalStorage();
    if (favoriteQuestions) {
      return _.map(favoriteQuestions, function(favoriteQuestion, key) {
        return (
          <div key={key} className='fbc'>
            <div className="merge-table fbl" onClick={() => that.openSavedQuestion(key)}>
              <span>{favoriteQuestion} </span> <small> ({key})</small>
            </div>
            <div className="remove-item-table highlight fbf" onClick={() => that.removeFavoriteQuestion(key) }><Icon disabled name='delete' /> {i18n("delete")}</div>
          </div>
        )
      });
    } else {
      return;
    }    
  }

  onModalOpen() {
    this.setState({ examsLoadingFinished: false });
    this.getUserExams();
    this.forceUpdate();
  }

  getLocalStorageData() {
    let data = JSON.parse(localStorage.getItem('ppltrainer'));
    if (!data) {
      data = {
        motor: true,
        heli: false,
        segel: false,
        ballon: false,
        country: 'AT',
        language: "de",
      }
    }
    return data;
  }

  getCategoryItems() {
    const items = [];

    const data = this.getLocalStorageData();
    if (data.motor) items.push("PPL(A)");
    if (data.heli) items.push("PPL(H)");
    if (data.segel) items.push("SPL");
    if (data.ballon) items.push("BPL");

    return items;
  }

  render() {
    const CategoryItems = this.getCategoryItems().join(", ");
    return (
      <>
        { this.state.openSavedQuestion && <SavedQuestionOverlay question={this.state.openSavedQuestion} onClose={() => this.setState({ openSavedQuestion: null })} /> }
        <Modal onOpen={this.onModalOpen} trigger={<Icon name="user" className="info-circle-menu" />} closeIcon className="settings-modal-container">
          <Header icon='user' content={i18n("my_ppltrainer")} />
          <Modal.Content>

            <label className="settings-item-header">{i18n("Fragenkatalog")}: {CategoryItems}</label>
            <div className="setting-item">
              <Button className="btn-second" onClick={this.props.onClickChooseCatalog}>{i18n("changeQuestionCatalog")}</Button>
            </div>
            
            <label className="settings-item-header">{i18n("trainingsmode")}</label>
            <div className="setting-item">
              <Radio
                toggle
                label={i18n("immediately_jump_to_next_question")}
                checked={this.state.checkedTrainingsModel}
                onChange={this.toggleTrainingsModel}
              />
            </div>

            <label className="settings-item-header">{i18n("my_flightschool")}</label>
            <div className="setting-item">
              {
                !this.props.atoDetails ?
                  <div className='settings-item-text'>{i18n("account_is_not_linked_to_any_flightschool")}</div>
                :
                <Radio
                  toggle
                  label={i18n("show_content_of_flightschool")}
                  checked={this.state.showQuestionsOfFlightschool}
                  onChange={this.toggleShowQuestionsOfFlightschoolModel}
                />
              }
            </div>

            {
              this.examsAreAvailable() ?
              <div className='setting-item'>
                <label className="settings-item-header">{i18n("my_last_exams")} <small className='custom-small-setting-hint'>({i18n("maximum_3_results")})</small></label>
                <div className="setting-item">
                  <div className='ui basic table exam-cards-wrapper'>
                    { this.getPreviousExams() }
                  </div>
                </div>
              </div>
              : null
            }

            {
              this.savedQuestionsAreAvailable() ?
              <div>
                <label className="settings-item-header">{i18n("favorite_questions_label")}</label>
                <div className="mt-10"></div>
                <div className="setting-item no-border">
                  <div className='saved-question-container'>
                    { this.getFavoriteQuestions() }
                  </div>
                </div>
              </div>
              : null
            }

          </Modal.Content>
        </Modal>
      </>
    );
  }
};

export default Settings;