import React, { Component } from 'react';
import { Icon, Modal, Dropdown } from 'semantic-ui-react';
import  _ from 'underscore';
import i18n from '../../../utils/i18n';

class QeustionsFormular extends Component {
  constructor(props) {
    super(props);
    const firstCategory = (this.props.categories && !_.isEmpty(this.props.categories)) ? this.props.categories[0] : "";

    let answer1 = null, answer2 = null, answer3 = null, answer4 = null, question = null, category = null, hidden = null, id = null, image = null;
    if (this.props.formularData) {
      ({ answer1, answer2, answer3, answer4, question, category, hidden, id, image } = this.props.formularData);
    }

    this.state = {
      category: category || firstCategory,
      question: question || "",
      answer1: answer1 || "",
      answer2: answer2 || "",
      answer3: answer3 || "",
      answer4: answer4 || "",
      image: image || null,
      hidden: hidden || false,
      id: id || null,
    };

    this.update = this.update.bind(this);
  }

  update(options, data) {
    let name = options.target.name || (data && data.name) || null;
    let value = options.target.value || (data && data.value) || null;

    if (options.target.files) {
      const file = options.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      name = "image";
      value = formData;
    }

    // TODO fix for deselecting checkbox 

    if (name === "hidden") {
      if (value === "on") {
        value = true;
      } else { 
        value = false;
      }
    }

    const obj = {};
    obj[name] = value;
    this.setState(obj, () => {
      const {category, question, answer1, answer2, answer3, answer4, image, hidden} = this.state;
      this.props.updateContent({
        data: {"question": { category, question, answer1, answer2, answer3, answer4, image, hidden }},
        id: 'question',
        questionID: this.state.id,
      });
    });
  }

  render() {
    const availableCategories = _.union(this.props.categories, ["AGK","PFA","PFB","PFH","MET","NAV","COM","ALW","HPL","OPR","FPP","NVFR"]);
    const selectableCategories = _.map(availableCategories, function(cat) { 
      const category = i18n(cat) || cat;
      return {
        key: cat,
        text: category,
        value: cat,
      }
    });

    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_question")}</Modal.Header>
      <Modal.Description>
        <div>
          <div className='mm-description-item'>{i18n("create_new_question_hint")}</div>
        </div>
      </Modal.Description>
      <div className='mm-form-container'>
        <form>
          <div className='mm-form-item'>
            <Icon name="question" title={i18n("question")} />
            <input type="text" id="" placeholder={i18n("question")} value={this.state.question} name="question" onChange={(options) => this.update(options)}/><br/>
          </div>
          <div className='mm-form-item'>
            <Icon name="checkmark" title={i18n("correct_answer")} />
            <input type="text" id="answer1" placeholder={i18n("correct_answer")} value={this.state.answer1} name="answer1" onChange={(options) => this.update(options)}/><br/>
          </div>
          <div className='mm-form-item'>
            <Icon name="x" title={i18n("other_answer")} />
            <input type="text" id="answer2" placeholder={i18n("other_answer")} value={this.state.answer2} name="answer2" onChange={(options) => this.update(options)}/><br/>
          </div>
          <div className='mm-form-item'>
            <Icon name="x" title={i18n("other_answer")} />
            <input type="text" id="answer3" placeholder={i18n("other_answer")} value={this.state.answer3} name="answer3" onChange={(options) => this.update(options)}/><br/>
          </div>
          <div className='mm-form-item'>
            <Icon name="x" title={i18n("other_answer")} />
            <input type="text" id="answer4" placeholder={i18n("other_answer")} value={this.state.answer4} name="answer4" onChange={(options) => this.update(options)}/><br/>
          </div>
          <div className='mm-form-item mm-form-item-additional-space'>
            <Icon name="list" title={i18n("category")}/>
            <Dropdown
              placeholder={i18n("category")}
              fluid
              selection
              options={selectableCategories}
              defaultValue={this.props.formularData ? this.state.category : selectableCategories[0].text}
              name="category" 
              onChange={(options, data) => this.update(options, data)}
            />
          </div>
          <div className='mm-form-item'>
            <Icon name="eye" title={i18n("show_question_only_for_exams_from_ato")} />
            <div className="ui checkbox">
              <input type="checkbox" name="hidden" id="check-hidden" checked={this.state.hidden} onChange={(options) => this.update(options)}/>
              <label htmlFor="check-hidden">{i18n("only_show_on_exams_of_ato_questions")}</label>
            </div>
          </div>
          <div className='mm-form-item'>
            <Icon name="file image" title={i18n("image")} />
            <input type="file" lang={document.documentElement.lang} accept="image/*" id="image" placeholder={i18n("image")} name="image" onChange={(options) => this.update(options)}/><br/>
          </div>
        </form>
      </div>  
    </Modal.Content>
  }
}

export default QeustionsFormular;
