import React, { Component } from 'react';
import _ from 'underscore';
import { Icon, Modal } from 'semantic-ui-react';

import AddScenarioQuestion from './AddScenarioQuestion';

import i18n from '../../../utils/i18n';

class ScenarioFormular extends Component {
  constructor(props) {
    super(props);

    let title = null, description = null, questions = null, id = null;

    if (this.props.formularData) {
      ({ title, description, questions, id } = this.props.formularData);
    }

    this.state = {
      title: title || '',
      description: description || '',
      id: id || null,
      questions: questions || [],
      addQuestion: false,
    };

    this.update = this.update.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
  }

  update(options, data) {
    const obj = {};

    if (options) {
      let name = options.target.name || (data && data.name) || null;
      let value = options.target.value || (data && data.value) || null;
      obj[name] = value;
    }

    this.setState(obj, () => {
      const { title, description, questions, id } = this.state;
      this.props.updateContent({
        data: {"scenario": { title, description, questions, id }},
        id: 'scenario',
      });
    });
  }

  addQuestion() {
    this.setState({ addQuestion: true });
  }

  removeQuestion(index) {
    const questions = this.state.questions;
    questions.splice(index, 1);
    this.setState({ questions });
    this.update();
  }

  setQuestion(item) {
    const { question, questionID, readableKey, correctAnswerIndex, answers, correctAnswer, image } = item;
    const questions = this.state.questions;
    questions.push({
      question, questionID, readableKey, correctAnswerIndex, answers, correctAnswer, image
    });
    this.setState({
      addQuestion: false,
      questions
    });
    this.update();
  }

  render() {
    const that = this;
    return <Modal.Content scrolling className="mm-content-container">
      <Modal.Header>{i18n("create_new_scenario")}</Modal.Header>

      {
        this.state.addQuestion ?
          <AddScenarioQuestion
            questions={this.props.questions}
            onSelectQuestion={this.setQuestion}
            onCancle={() => this.setState({ addQuestion: false })}
          />
        :
        <div className='mm-form-container create-answer'>
          <div className='headline'>
            <span className='headline-description'>
              {i18n("scenario_adding_description")} {i18n("scenario_adding_description_part2")}
            </span>
          </div>
          <div className='mm-form-container'>
            <div className='mm-form-item'>
              <Icon name="info" title={i18n("title")} />
              <input type="text" placeholder={i18n("title_of_scenario")} value={this.state.title} name="title" onChange={(options) => this.update(options)}/><br/>
            </div>
            <div className='mm-form-item no-border'>
              <textarea className='scenario-description-textarea' value={this.state.description} placeholder={i18n("description_of_scenario")} name="description" onChange={(options) => this.update(options)}></textarea>
            </div>

            <div className='headline mt-5'>
              {i18n("select_questions")} 
              <span className='headline-description'>{i18n("question_will_be_shown_cronologically")}</span>
            </div>

            <div className='scenarios-question-list-container'>
              {
                this.state.questions.length > 0 ?
                <ul>{
                  _.map(this.state.questions, function (item, index) {
                    // const question = item;
                    return <li key={index}>
                      <div className="question-scenario-item fbc">
                        <span className='fbl'>{item.question}</span>
                        <button
                          className='btn btn-second fbf'
                          onClick={() => that.removeQuestion(index)}
                        >{i18n('delete')}</button>
                      </div>
                    </li>;
                  })
                }</ul>
                :
                <div className='font-italic'>{i18n('no_questions_selected')}</div>
              }
            </div>

            <button
              onClick={() => this.addQuestion()}
              className='add-new-question-container btn btn-primary'>
              <Icon name="add" /> { this.state.questions.length < 1 ? i18n('add_question') : i18n('add_another_question') }
            </button>

          </div>
        </div>  
      }
    </Modal.Content>
  }
}

export default ScenarioFormular;
